<template>
    <li v-if="canViewVerticalNavMenuGroup(item) && isRoled(item)" class="nav-item has-sub" :class="{
        open: isOpen,
        disabled: item.disabled,
        'sidebar-group-active': isActive
    }">
        <b-link class="d-flex align-items-center" @click="() => updateGroupOpen(!isOpen)">
            <unicon width="18" :name="item.icon || 'circle'" fill="#5E5873" />

            <span class="menu-title text-truncate">{{ item.title }}</span>
            <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
                {{ item.tag }}
            </b-badge>
        </b-link>
        <b-collapse v-model="isOpen" class="menu-content" tag="ul">
            <component :is="resolveNavItemComponent(child)" v-for="child in item.children"
                :key="child.header || child.title" ref="groupChild" :item="child" :isRoled="isRoled(child)" />
        </b-collapse>
    </li>
</template>

<script>
import { BLink, BBadge, BCollapse } from "bootstrap-vue";
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useUtils as useAclUtils } from "@core/libs/acl";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";

// Composition Function
import useVerticalNavMenuGroup from "./useVerticalNavMenuGroup";
import mixinVerticalNavMenuGroup from "./mixinVerticalNavMenuGroup";
import { getUserData } from "@/app/admin/auth/utils";
import jwtDecode from "jwt-decode";

export default {
    name: "VerticalNavMenuGroup",
    components: {
        VerticalNavMenuHeader,
        VerticalNavMenuLink,
        BLink,
        BBadge,
        BCollapse
    },
    mixins: [mixinVerticalNavMenuGroup],
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const {
            isOpen,
            isActive,
            updateGroupOpen,
            updateIsActive
        } = useVerticalNavMenuGroup(props.item);

        const { t } = useI18nUtils();
        const { canViewVerticalNavMenuGroup } = useAclUtils();

        return {
            resolveNavItemComponent,
            isOpen,
            isActive,
            updateGroupOpen,
            updateIsActive,

            // ACL
            canViewVerticalNavMenuGroup,

            // i18n
            t
        };
    },
    methods: {
        isRoled(item) {

            // item
            //   return item.roles.indexOf(getUserData().role) != -1
            return (
                item.roles.indexOf(
                    jwtDecode(getUserData().accessToken)[
                    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                    ]
                ) != -1
            );
        }
    }
};
</script>

<style>
</style>
