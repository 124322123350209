import { Admin, SuperAdmin, Guide, Teacher, DataEntry } from "@/router";
export default [
    {
        title: "الرئيسية",
        route: "/admin/home",
        icon: "home-alt",
        roles: [Admin, SuperAdmin]
    },
    {
        header: "ادارة المحتوى",
        roles: [Admin, SuperAdmin, DataEntry]
    },
    {
        title: "الصفوف",
        route: "/admin/classes",
        icon: "university",
        roles: [Admin, SuperAdmin, DataEntry]
    },
    {
        title: "المواد",
        route: "/admin/subjects",
        icon: "books",
        roles: [Admin, SuperAdmin, DataEntry]
    },
    {
        title: "الوحدات",
        route: "/admin/units",
        icon: "bookmark",
        roles: [Admin, SuperAdmin, Teacher, DataEntry]
    },
    {
        title: "الأبحاث",
        route: "/admin/chapter",
        icon: "bookmark",
        roles: [Admin, SuperAdmin, Teacher, DataEntry]
    },
    {
        title: "الدروس",
        route: "/admin/lessons",
        icon: "file-question-alt",
        roles: [Admin, SuperAdmin, Teacher, DataEntry]
    },
    {
        title: "بنك الاسئلة ",
        route: "/admin/questionsBank",
        icon: "question-circle",
        roles: [Admin, SuperAdmin, DataEntry]
    },
    {
        title: "الاعلانات",
        route: "/admin/adverts",
        icon: "megaphone",
        roles: [Admin, SuperAdmin, DataEntry]
    },
    {
        title: "الاشعارات",
        route: "/admin/notifications",
        icon: "bell",
        roles: [Admin, SuperAdmin, DataEntry]
    },

    {
        header: "المالية والارباح",
        roles: [Admin, SuperAdmin]
    },
    {
        title: "رموز التفعيل",
        route: "/admin/activationCode",
        icon: "university",
        roles: [Admin, SuperAdmin]
    },
    {
        title: "كشف حساب",
        icon: "bill",
        roles: [Admin, SuperAdmin],
        children: [
            {
                title: "  نقاط البيع",
                route: "/admin/invoice/invoiceSellPoint",
                roles: [Admin, SuperAdmin]
            },
            {
                title: "مجيبو الاسئلة",
                route: "/admin/invoice/invoiceGuide",
                roles: [Admin, SuperAdmin]
            },
            {
                title: "  الاساتذة",
                route: "/admin/invoice/invoiceTeacher",
                roles: [Admin, SuperAdmin]
            }
        ]
    },
    {
        header: "الحسابات",
        roles: [Admin, SuperAdmin]
    },
    {
        title: "مستخدم التطبيق",
        route: "/admin/students",
        icon: "users-alt",
        roles: [Admin, SuperAdmin]
    },

    {
        title: "الأساتذة",
        route: "/admin/teachers",
        icon: "user-circle",
        roles: [Admin, SuperAdmin]
    },
    {
        title: " نقاط البيع",
        route: "/admin/sellpoints",
        icon: "money-withdraw",
        roles: [Admin, SuperAdmin]
    },
    {
        title: " مجيبو الاسئلة",
        route: "/admin/questionsAnswerer",
        icon: "envelope-question",
        roles: [Admin, SuperAdmin]
    },
    {
        title: "الاسئلة الواردة",
        route: "/admin/questionAnswerDashBoard/incommingQuestion",
        icon: "envelope-question",
        roles: [SuperAdmin, Guide]
    },
    {
        title: "سجل الاجابات",
        route: "/admin/questionAnswerDashBoard/questionRecord",
        icon: "envelope-question",
        roles: [SuperAdmin, Guide]
    },

    {
        header: "الاعدادات",
        roles: [Admin, SuperAdmin]
    },
    {
        title: "مستخدمو اللوحة",
        route: "/admin/dashboardUsers",
        icon: "user-circle",
        roles: [Admin, SuperAdmin]
    },
    {
        title: "الإعدادات",
        route: "/admin/settings",
        icon: "moneybag",
        roles: [Admin, SuperAdmin]
    },
    {
        title: "بريد الشركة",
        route: "/admin/contactUs",
        icon: "envelope-download",
        roles: [Admin, SuperAdmin]
    }
];
